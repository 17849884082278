import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import Section from "../components/Section";
import SectionRow from "../components/SectionRow";
import SVGCurve from "../components/SVGCurve";
import SEO from "../components/SEO";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import AppointmentDiv from "../components/AppointmentDiv";
import {DivListItemCol, DivListItemRow} from "../components/DivListItem";
import DivWithText from "../components/DivWithText";
import UnorderedList from "../components/UnorderedList";
import ExternalLink from "../components/ExternalLink";
import InternalLink from "../components/InternalLink";

function ServicesPage() {
    return (
        <LayoutGeneral>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Services`]}
                title="Services"
                description="Services offered through Agile Psychiatry and Brian Nahas, MD"
            />

            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-2">Services</h2>
                        <DivWithText className="border-solid border-ap-dark-bg border-2 rounded-md p-4 mt-4 mb-4 bg-ap-dark-bg text-white">
                            <h4 className="text-2xl mb-2">Practice Closed</h4>
                            <UnorderedList className="ml-6">
                                <li className="mb-2 text-2xl"><span className="italic">Dr. Nahas closed his clinical practice at Agile Psychiatry, effective 1/31/23</span></li>
                                <li className="mb-2 text-2xl">See the <InternalLink to="practice-closure-notice">Practice Closure Notice</InternalLink> for more information</li>
                            </UnorderedList>
                        </DivWithText>
                        {/*<DivWithText className="border-solid border-ap-dark-bg border-2 rounded-md p-4 mt-4 mb-4 bg-ap-dark-bg text-white">*/}
                        {/*    <h4 className="text-2xl mb-2">Overview</h4>*/}
                        {/*    <UnorderedList className="ml-6">*/}
                        {/*        /!*<li>Accepting new patients from 18-60 years old</li>*!/*/}
                        {/*        <li className="mb-2 text-2xl"><span className="italic">Dr. Nahas is not accepting new patients at this time</span></li>*/}
                        {/*        <li>Serving patients from 18-60 years old</li>*/}
                        {/*        <li>In-person appointments available for patients in Austin / Cedar Park / Georgetown</li>*/}
                        {/*        <li>Telepsychiatry appointments available for patients located anywhere in Texas</li>*/}
                        {/*        <li>Initial appointment: $290</li>*/}
                        {/*        <li>Follow-ups: $165 (medication) $230 (therapy + medication)</li>*/}
                        {/*        <li>Online payment with &quot;Superbill&quot; receipts for insurance claims (insurance not accepted at this time)</li>*/}
                        {/*        <li>Controlled medications available for Austin-area patients</li>*/}
                        {/*    </UnorderedList>*/}
                        {/*</DivWithText>*/}
                        <DivWithText className="border-solid border-ap-dark-bg border-2 rounded-md p-4 mb-8 bg-ap-dark-bg text-white">
                            <h4 className="text-2xl mb-2">Good Faith Estimate</h4>
                            <UnorderedList className="ml-6">
                                <li>Under the &quot;No Surprises Act&quot;, you may have the right to receive a &quot;Good Faith Estimate&quot; for the cost of your care</li>
                                <li>Please visit the <InternalLink to="good-faith-estimate-notice">Good Faith Estimate Notice</InternalLink> for more information </li>
                            </UnorderedList>
                        </DivWithText>
                        <TextParagraph className="mb-8">
                            Agile Psychiatry offers medication management, psychotherapy, or combined appointments under the
                            care of Dr. Nahas. Appointments are available online via secure video or in person at
                            the Agile Psychiatry Clinic in Cedar Park, TX.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            During medication management appointments, Dr. Nahas will evaluate your symptoms and develop
                            a treatment plan to address them. This may or may not involve medication as they
                            are not always indicated. Sometimes lifestyle adjustments, therapy, and addressing a general
                            medical condition can result in the improvement desired. If medications are
                            indicated, Dr. Nahas believes in using only what is necessary to completely alleviate
                            symptoms and works closely with his patients to adjust medications to achieve the desired improvement.
                            <ExternalLink to="https://www.deadiversion.usdoj.gov/schedules/"> Controlled medications</ExternalLink> are
                            available to patients that will be able to attend  in-person appointments, but please anticipiate
                            that in most cases they will not be prescribed at the initial appointment.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            Dr. Nahas is trained in various forms of psychotherapy including Cognitive Behavioral Therapy (CBT),
                            Supportive Therapy, and Psychodynamic Psychotherapy. He believes that therapy can be an important part of
                            recovery and will discuss with you a therapy plan that he feels will help you best. Dr. Nahas&apos; current focus is on providing
                            goal-oriented CBT and supportive therapy limited to durations of 3-4 months in most cases. If
                            Dr. Nahas believes you would benefit from long term therapy he will work with you find a good
                            long-term therapist for your needs.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-2">Diagnoses</h2>
                        <TextParagraph className="mb-8">
                            Dr. Nahas treats a wide array of issues, including but not limited to:
                        </TextParagraph>
                        <div className="m-4 flex flex-col text-xl md:text-2xl">
                            <DivListItemRow breakPoint="md">
                                <DivListItemCol className="md:w-1/2">Anxiety</DivListItemCol>
                                <DivListItemCol className="md:w-1/2">Depression</DivListItemCol>
                            </DivListItemRow>
                            <DivListItemRow breakPoint="md">
                                <DivListItemCol className="md:w-1/2">ADHD</DivListItemCol>
                                <DivListItemCol className="md:w-1/2">Insomnia</DivListItemCol>
                            </DivListItemRow>
                            <DivListItemRow breakPoint="md">
                                <DivListItemCol className="md:w-1/2">Grief / Loss</DivListItemCol>
                                <DivListItemCol className="md:w-1/2">Stress</DivListItemCol>
                            </DivListItemRow>
                            <DivListItemRow breakPoint="md">
                                <DivListItemCol className="md:w-1/2">Panic disorder</DivListItemCol>
                                <DivListItemCol className="md:w-1/2">Social Anxiety</DivListItemCol>
                            </DivListItemRow>
                            <DivListItemRow breakPoint="md">
                                <DivListItemCol className="md:w-1/2">Bipolar Disorder</DivListItemCol>
                                <DivListItemCol className="md:w-1/2">Substance Use</DivListItemCol>
                            </DivListItemRow>
                            <DivListItemRow breakPoint="md">
                                <DivListItemCol className="md:w-1/2">Phobias</DivListItemCol>
                                <DivListItemCol className="md:w-1/2">And more...</DivListItemCol>
                            </DivListItemRow>
                        </div>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-2">Fees</h2>
                        <TextParagraph className="mb-8">
                            Agile Psychiatry does not accept insurance plans, Medicare, or Medicaid, all of which can place
                            restrictions on time spent providing treatment and the type of treatment provided. However,
                            Agile Psychiatry can provide you with a &quot;Super Bill&quot; after your appointments which
                            can be submitted along with a claim to your insurance company. In this case, Agile Psychiatry
                            will likely be classified as an &quot;Out of Network&quot; provider under your insurance plan.
                            If you have questions about out-of-network reimbursement, please contact your insurance provider.
                            You will still be able to use your insurance plan for medications and lab work if it provides
                            coverage for them.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            Payments are required on the day of the services via credit card or debit card online. Note
                            that Agile Psychiatry charges for services that take more than 10 minutes outside of schedule appointment times
                            (phone calls, messages, refill requests, documentation, etc). Insurance plans typically do not reimburse for this time. Please see
                            the &quot;Additional Care Time&quot; section below for more detailed information. Agile Psychiatry also charge 50% of the appointment fee if scheduled
                            appointments are cancelled with less than 2 business days notice.
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            <span className="font-semibold">For complex diagnoses, including new ADHD diagnoses,
                                typically there will be additional time required beyond the initial appointment</span> in order to
                            determine a diagnosis and select appropriate treatment. For more information about the cost of care at Agile Psychiatry, please review our page
                            on requesting a <InternalLink to="good-faith-estimate-notice">Good Faith Estimate</InternalLink>.
                        </TextParagraph>
                        {/*<TextParagraph className="mb-8">*/}
                        {/*    This page will be updated with a fee schedule after Agile*/}
                        {/*    Psychiatry launches in January 2021. Please check back in the near future for details.*/}
                        {/*</TextParagraph>*/}
                        <div className="mb-4">
                            <p className="text-xl font-semibold">Initial evaluation: $290</p>
                            <ul className="list-disc list-inside ml-2">
                                <li>Scheduled for 90 minutes
                                    <ul className="list-disc list-inside ml-2">
                                        <li>60-75 minute conversation</li>
                                        <li>15-30 minutes of administrative time (documentation, ordering medications, etc)</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        {/*<TextParagraph className="mb-4">*/}
                        {/*    <h3 className="text-xl">Initial evaluation (16-17 year olds): $350</h3>*/}
                        {/*    <ul className="list-disc list-inside ml-2">*/}
                        {/*        <li>~90 minutes (~75 minute interview + ~15 minutes documentation)</li>*/}
                        {/*        <li>Interview consists of patient and parent individually and together</li>*/}
                        {/*    </ul>*/}
                        {/*</TextParagraph>*/}
                        <div className="mb-4">
                            <p className="text-xl font-semibold">Follow-up (Medication Management): $165</p>
                            <ul className="list-disc list-inside ml-2">
                                <li>Scheduled for 30 minutes
                                    <ul className="list-disc list-inside ml-2">
                                        <li>20 minute conversation</li>
                                        <li>10 minutes of administrative time (documentation, ordering medications, etc)</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="mb-4">
                            <p className="text-xl font-semibold">Follow-up (Therapy &amp; Medication Management): $230</p>
                            <ul className="list-disc list-inside ml-2">
                                <li>Scheduled for 60 minutes
                                    <ul className="list-disc list-inside ml-2">
                                        <li>45 minute conversation</li>
                                        <li>15 minutes of administrative time (documentation, ordering medications, etc)</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="mb-4">
                            <p className="text-xl font-semibold">Additional Time (calls, messages, etc): 10 minutes free then $64 / 10 minutes</p>
                            <ul className="list-disc list-inside ml-2">
                                <li>If something takes less than 10 minutes there is no charge</li>
                                <li>Examples:
                                    <ul className="list-disc list-inside ml-2">
                                        <li>Phone call for 7 minutes: No charge</li>
                                        <li>Responding to a written message for 15 minutes: $64</li>
                                        <li>Phone call for 35 minutes: $192</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="mb-4">
                            <p className="text-lg font-semibold italic">Late Cancellations (&lt; 2 business days notice): 50% of appointment fee</p>
                        </div>
                        {/*<TextParagraph className="mb-4">*/}
                        {/*    <h3 className="text-xl font-semibold">Second opinion or consultation for another doctor: $450</h3>*/}
                        {/*    <ul className="list-disc list-inside ml-2">*/}
                        {/*        <li>~90-120 minutes</li>*/}
                        {/*    </ul>*/}
                        {/*</TextParagraph>*/}
                        {/*<TextParagraph className="mb-4">*/}
                        {/*    <h3 className="text-xl font-semibold">Other</h3>*/}
                        {/*    <ul className="list-disc list-inside ml-2">*/}
                        {/*        <li>A pro-rated hourly rates is applied for care (phone calls, paperwork) outside*/}
                        {/*            of the above listed sessions. See your Practice Policies form for more details.*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</TextParagraph>*/}
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-dark"/>
            <Section shade="dark">
                <SectionRow>
                    <AppointmentDiv/>
                </SectionRow>
            </Section>
            {/*<SVGCurve direction="right-slope" color="text-ap-dark-bg" bgColor="bg-gray-200"/>*/}
        </LayoutGeneral>
    );
}


export default ServicesPage;
